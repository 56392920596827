module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GlobeTrott APP","short_name":"GlobeTrott APP","start_url":"/","background_color":"#130A3B","theme_color":"#130A3B","display":"minimal-ui","icon":"src/images/logo.png","prefer_related_applications":true,"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=com.globetrott","id":"com.globetrott"},{"platform":"itunes","url":"https://apps.apple.com/lt/app/globetrott-travel-audio-guide/id1553250015"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2476df3186af89ec00b77ce514251dfa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
